import service from '@/utils/request.js'
// 导入签名计算
import { encrypt } from '@/utils/encrypt'

/************************************
 * 套餐组
 ************************************/


 /**
  * 
  * @param {套餐列表} data 
  */
export function combo_list(data={}) {
  return service.request({
    url: '/admin/set_meal/list',
    method: 'get',
    params:data
  })
}


 /**
  * 
  * @param {添加套餐} data 
  */
 export function combo_save(data={}) {
    return service.request({
      url: '/admin/set_meal/save',
      method: 'post',
      data
    })
  }


   /**
  * 
  * @param {删除套餐} data 
  */
export function combo_del(data={}) {
    return service.request({
      url: '/admin/set_meal/delete',
      method: 'post',
      data
    })
  }

/**
 * 编辑套餐
 */
export function combo_update(data={}) {
  return service.request({
    url: '/admin/set_meal/update',
    method: 'post',
    data
  })
}