<template>
    <div>
        <div class="entrepot-title-all">
            <div class="entrepot-title">
                <div @click="warehouseClick(1)" :class="[pageType == 1?'entrepot-color':'']">采购管理</div>
                <div @click="warehouseClick(2)" :class="[pageType == 2?'entrepot-color':'']">统计查询</div>
            </div>
            <div class="flex-all">
                <div class="flex-allll">
                    <el-switch
                    v-show="pageType == 2"
                    v-model="tongji.is_shipment"
                    active-text="未出货"
                    inactive-text="已出货">
                    </el-switch>
                </div>
                <el-button v-show="pageType == 2" class="add-classs" @click="addEditData" >添加套账</el-button>
                <el-row :gutter="20">
                    <el-col :span="16">
                        <el-input
                            placeholder="输入商品型号"
                            prefix-icon="el-icon-search"
                            v-model="limit.goods_type">
                        </el-input>
                    </el-col>
                    <el-col :span="4"><el-button class="search-class" @click="selecCLick" >搜索</el-button></el-col>
                </el-row>
                <!-- <el-button class="addButton-class" @click="dialogFormVisible = true " >批量导入库</el-button> -->
                <el-button class="start-class" @click="addpopupStatus = true" >启动扫码入库</el-button>
                <el-button class="add-class" @click="dialogFormVisible = true " >新增商品</el-button>
            </div>
        </div>
        

        <div v-show="pageType == 1">
            <el-table
            v-loading="loading"
            element-loading-text="拼命加载中"
            element-loading-background="#fff"
            :data="tableData"
            border
            stripe
            style="width: 100%">
                <el-table-column
                    width="80"
                    show-overflow-tooltip
                    label="日期">
                    <template scope="scope">
                        {{scope.row.create_time | timeFilter}}
                    </template>
                </el-table-column>
                <el-table-column
                    width="100"
                    prop="goods_order"
                    label="单号">
                    <template scope="scope">
                        <div @dblclick="cellOneClick(scope.$index,scope.row)">
                            <span>{{scope.row.goods_order}}</span>
                        </div>
                    </template>
                </el-table-column>

                <el-table-column
                    width="100"
                    label="商品图片">
                    <template scope="scope">
                        <el-image
                        style="width: 80px; height: 80px"
                        :src="scope.row.goods_url | replaceUrl"></el-image>
                    </template>
                </el-table-column>

                <el-table-column
                    width="100"
                    prop="bar_code"
                    label="条形码">
                    <template scope="scope">
                        <div @dblclick="cellOneClick(scope.$index,scope.row)">
                            <span>{{scope.row.bar_code}}</span>
                        </div>
                    </template>
                </el-table-column>

                <el-table-column
                    width="100"
                    label="二维码">
                    <template scope="scope">
                        <div  v-if="scope.row.editStatus">
                            <el-input 
                                v-if="scope.row.editStatus"
                                size="small" 
                                v-model="scope.row.qr_code" ></el-input>
                        </div>
                        <div v-else @dblclick="cellOneClick(scope.$index,scope.row)">
                            <vue-qr v-if="scope.row.qr_code" :margin="10"   :text="scope.row.qr_code" :size="80"></vue-qr>
                            <div v-else>暂无数据</div>
                        </div>
                        
                    </template>
                </el-table-column>

                <el-table-column
                    width="100"
                    label="详情图">
                    <template scope="scope">
                        <el-image
                        style="width: 80px; height: 80px"
                        :src="scope.row.goods_detail | replaceUrl"></el-image>
                    </template>
                </el-table-column>

                <el-table-column
                    width="80"
                    prop="goods_type"
                    label="型号">
                    <template scope="scope">
                        <div @dblclick="cellOneClick(scope.$index,scope.row)">
                            <!-- <el-input 
                                v-if="scope.row.editStatus"
                                size="small" 
                                v-model="scope.row.goods_type" ></el-input> -->
                            <span>{{scope.row.goods_type}}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    width="70"
                    prop="goods_brand"
                    label="品牌">
                    <template scope="scope">
                        <div @dblclick="cellOneClick(scope.$index,scope.row)">
                            <!-- <el-input 
                                v-if="scope.row.editStatus"
                                size="small" 
                                v-model="scope.row.goods_brand" ></el-input> -->
                            <span>{{scope.row.goods_brand}}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    width="70"
                    prop="goods_norms"
                    label="规格">
                    <template scope="scope">
                        <div @dblclick="cellOneClick(scope.$index,scope.row)">
                            <!-- <el-input 
                                v-if="scope.row.editStatus"
                                size="small" 
                                v-model="scope.row.goods_norms" ></el-input> -->
                            <span>{{scope.row.goods_norms}}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    width="70"
                    prop="goods_class"
                    label="类别">
                    <template scope="scope">
                        <div @dblclick="cellOneClick(scope.$index,scope.row)">
                            <!-- <el-input 
                                v-if="scope.row.editStatus"
                                size="small" 
                                v-model="scope.row.goods_class" ></el-input> -->
                            <span>{{scope.row.goods_class}}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    width="80"
                    prop="goods_color"
                    label="颜色">
                    <template scope="scope">
                        <div @dblclick="cellOneClick(scope.$index,scope.row)">
                            <!-- <el-input 
                                v-if="scope.row.editStatus"
                                size="small" 
                                v-model="scope.row.goods_color" ></el-input> -->
                            <span>{{scope.row.goods_color}}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                prop="price"
                    label="单价">
                    <template scope="scope">
                        <div @dblclick="cellOneClick(scope.$index,scope.row)">
                            <!-- <el-input 
                                v-if="scope.row.editStatus"
                                size="small" 
                                v-model="scope.row.price" ></el-input> -->
                            <span>{{scope.row.price}}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    width="70"
                    label="数量">
                    <template >
                        <div @dblclick="cellOneClick(scope.$index,scope.row)">
                            <span>1</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    label="金额">
                    <template scope="scope">
                        <div @dblclick="cellOneClick(scope.$index,scope.row)">
                            <!-- <el-input 
                                v-if="scope.row.editStatus"
                                size="small" 
                                v-model="scope.row.reward" ></el-input> -->
                            <span>{{Allprice(scope.row.price,1)}}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                prop="reward"
                    label="奖励">
                    <template scope="scope">
                        <div @dblclick="cellOneClick(scope.$index,scope.row)">
                            <!-- <el-input 
                                v-if="scope.row.editStatus"
                                size="small" 
                                v-model="scope.row.reward" ></el-input> -->
                            <span>{{scope.row.reward}}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                prop="goods_other"
                    label="附带配件">
                    <template scope="scope">
                        <div @dblclick="cellOneClick(scope.$index,scope.row)">
                            <!-- <el-input 
                                v-if="scope.row.editStatus"
                                size="small" 
                                v-model="scope.row.goods_other" ></el-input> -->
                            <span >{{scope.row.goods_other}}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                width="200"
                    label="备注">
                    <template scope="scope">
                        <div @dblclick="cellOneClick(scope.$index,scope.row)">
                            <el-input 
                                v-if="scope.row.editStatus"
                                size="small" 
                                v-model="scope.row.remark" ></el-input>
                            <span v-else class="beizhu">{{scope.row.remark}}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    label="操作">
                    <template scope="scope">
                        <el-button type="text" @click="delClick(scope.row)" >删除</el-button>
                        <el-button v-if="scope.row.editButton" @click="editClick(scope.$index,scope.row)" type="text" >保存</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <el-pagination
            class="table-limit"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="pageArray"
            :page-size= this.limit.page_size
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
            </el-pagination>

        </div>

        <div v-show="pageType == 2">
            <el-table
            v-loading="loading"
            element-loading-text="拼命加载中"
            element-loading-background="#fff"
            :data="tableDatas"
            ref="multipleTable"
            border
            stripe
            @selection-change="handleSelectionChange"
            style="width: 100%">
                <el-table-column
                    align="ccenter"
                    type="selection"
                    show-overflow-tooltip
                    width="55">
                </el-table-column>
                <el-table-column
                    width="100"
                    label="日期">
                    <template scope="scope">
                        {{scope.row.create_time | timeFilter}}
                    </template>
                </el-table-column>
                

                <el-table-column
                    width="120"
                    label="商品图片">
                    <template scope="scope">
                        <el-image
                        style="width: 100px; height: 100px"
                        :src="scope.row.goods_url | replaceUrl"></el-image>
                    </template>
                </el-table-column>

                <el-table-column
                    width="140"
                    label="条形码">
                    <template slot-scope="scope">
                        <div @dblclick="cellOneClicks(scope.$index,scope.row)">
                        {{scope.row.bar_code}}
                        </div>
                    </template>
                </el-table-column>

                <el-table-column
                    width="120"
                    label="二维码">
                    <template scope="scope">
                        <vue-qr v-if="scope.row.qr_code" :margin="10"  :text="scope.row.qr_code" :size="100"></vue-qr>
                        <div v-else>暂无数据</div>
                    </template>
                </el-table-column>


                <el-table-column
                    label="型号">
                    <template scope="scope">
                        <div @dblclick="cellOneClick(scope.$index,scope.row)">
                            <!-- <el-input 
                                v-if="scope.row.editStatus"
                                size="small" 
                                v-model="scope.row.goods_type" ></el-input> -->
                            <span >{{scope.row.goods_type}}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    width="120"
                    label="详情图">
                    <template scope="scope">
                        <el-image
                        style="width: 100px; height: 100px"
                        :src="scope.row.goods_detail | replaceUrl"></el-image>
                    </template>
                </el-table-column>
                <el-table-column
                    width="80"
                    show-overflow-tooltip
                    label="品牌">
                    <template scope="scope">
                        <div @dblclick="cellOneClick(scope.$index,scope.row)">
                            <!-- <el-input 
                                v-if="scope.row.editStatus"
                                size="small" 
                                v-model="scope.row.goods_brand" ></el-input> -->
                            <span >{{scope.row.goods_brand}}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    width="80"
                    show-overflow-tooltip
                    label="规格">
                    <template scope="scope">
                        <div @dblclick="cellOneClick(scope.$index,scope.row)">
                            <!-- <el-input 
                                v-if="scope.row.editStatus"
                                size="small" 
                                v-model="scope.row.goods_norms" ></el-input> -->
                            <span >{{scope.row.goods_norms}}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    width="80"
                    label="类别">
                    <template scope="scope">
                        <div @dblclick="cellOneClick(scope.$index,scope.row)">
                            <!-- <el-input 
                                v-if="scope.row.editStatus"
                                size="small" 
                                v-model="scope.row.goods_class" ></el-input> -->
                            <span >{{scope.row.goods_class}}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    width="80"
                    label="颜色">
                    <template scope="scope">
                        <div @dblclick="cellOneClick(scope.$index,scope.row)">
                            <!-- <el-input 
                                v-if="scope.row.editStatus"
                                size="small" 
                                v-model="scope.row.goods_color" ></el-input> -->
                            <span >{{scope.row.goods_color}}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    label="单价">
                    <template scope="scope">
                        <div @dblclick="cellOneClick(scope.$index,scope.row)">
                            <!-- <el-input 
                                v-if="scope.row.editStatus"
                                size="small" 
                                v-model="scope.row.price" ></el-input> -->
                            <span >{{scope.row.price}}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    width="70"
                    label="数量">
                    <template scope="scope">
                        <div @dblclick="cellOneClick(scope.$index,scope.row)">
                            <span>{{scope.row.goods_num}}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    label="金额">
                    <template scope="scope">
                        <div @dblclick="cellOneClick(scope.$index,scope.row)">
                            <!-- <el-input 
                                v-if="scope.row.editStatus"
                                size="small" 
                                v-model="scope.row.reward" ></el-input>
                            <span v-else class="beizhu">{{scope.row.reward}}</span> -->
                            <span >{{Allprice(scope.row.price,scope.row.goods_num)}}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    label="奖励">
                    <template scope="scope">
                        <div @dblclick="cellOneClicks(scope.$index,scope.row)">
                            <el-input 
                                v-if="scope.row.editStatus"
                                size="small" 
                                @keyup.enter.native="updataData(scope.$index,scope.row)"
                                v-model="scope.row.reward" ></el-input>
                            <!-- <span v-else class="beizhu">{{scope.row.reward}}</span> -->
                            <span  v-else >{{scope.row.reward}}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    label="附带配件">
                    <template scope="scope">
                        <div @dblclick="cellOneClick(scope.$index,scope.row)">
                            <!-- <el-input 
                                v-if="scope.row.editStatus"
                                size="small" 
                                v-model="scope.row.goods_other" ></el-input> -->
                            <span  >{{scope.row.goods_other}}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    label="备注">
                    <template scope="scope">
                        <div @dblclick="cellOneClicks(scope.$index,scope.row)">
                            <el-input 
                                v-if="scope.row.editStatus"
                                size="small" 
                                v-model="scope.row.remark" ></el-input>
                            <span v-else class="beizhu">{{scope.row.remark}}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    label="操作">
                    <template scope="scope">
                      <el-upload
                        action="asd"
                        :multiple="false"
                        name="image"
                        :show-file-list="false"
                        :limit="100"
                        :http-request="uploadsClick"
                        ><el-button @click="updataimage(scope.row)" type="text" size="small">修改详情图</el-button></el-upload>  
                        <el-button v-if="scope.row.editStatus" @click="updataData(scope.$index,scope.row)" type="text" size="small">保存</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <el-pagination
            class="table-limit"
            @size-change="handleSizeChanges"
            @current-change="handleCurrentChanges"
            :current-page="currentPage"
            :page-sizes="pageArray"
            :page-size= this.tongji.page_size
            layout="total, sizes, prev, pager, next, jumper"
            :total="totals">
            </el-pagination>

        </div>


        <el-dialog title="新增商品" :visible.sync="dialogFormVisible" @close="coloseClick" :destroy-on-close="true" width="40%" >
            <el-form :model="formInline" class="demo-form-inline">
                <el-row :gutter="20">
                    <el-col :span="11">
                        <el-form-item label="条形码：" label-width="100px">
                            <el-input class="input-all"  v-model="formInline.bar_code" placeholder="条形码"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="11">
                        <el-form-item label="颜色：" label-width="100px">
                            <el-input class="input-all"  v-model="formInline.goods_color" placeholder="颜色"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="11">
                        <el-form-item label="类别：" label-width="100px">
                            <el-input class="input-all"  v-model="formInline.goods_class" placeholder="类别"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="11">
                        <el-form-item label="数量：" label-width="100px">
                            <el-input class="input-all" disabled v-model="formInline.goods_num" placeholder="数量"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="11">
                        <el-form-item label="型号：" label-width="100px">
                            <el-input class="input-all"  v-model="formInline.goods_type" placeholder="型号"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="11">
                        <el-form-item label="金额：" label-width="100px">
                            <el-input class="input-all"  v-model="formInline.price" placeholder="金额"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="11">
                        <el-form-item label="品牌：" label-width="100px">
                            <el-input class="input-all"  v-model="formInline.goods_brand" placeholder="品牌"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="11">
                        <el-form-item label="奖励：" label-width="100px">
                            <el-input class="input-all"  v-model="formInline.reward" placeholder="奖励"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="11">
                        <el-form-item label="规格：" label-width="100px">
                            <el-input class="input-all"  v-model="formInline.goods_norms" placeholder="规格"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="11">
                        <el-form-item label="附带配件：" label-width="100px">
                            <el-input class="input-all"  v-model="formInline.goods_other" placeholder="附带配件"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="11">
                        <el-form-item label="二维码：" label-width="100px">
                            <el-input class="input-all"  v-model="formInline.qr_code" placeholder="二维码"></el-input>
                            <!-- <el-upload
                            action="asd"
                            :http-request="qrCodeClick"
                            :multiple="false"
                            name="image"
                            :limit="10"
                            :file-list="fileList">
                                <el-button size="small" type="primary">点击上传</el-button>
                            </el-upload> -->
                        </el-form-item>
                    </el-col>
                    <el-col :span="11">
                        <el-form-item label="商品图片：" label-width="100px">
                            <el-upload
                            action="asd"
                            :http-request="uploadClick"
                            :multiple="false"
                            name="image"
                            :limit="1"
                            :file-list="fileList">
                                <el-button size="small" type="primary">点击上传</el-button>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="11">
                        <el-form-item label="商品详情图：" label-width="100px">
                            <el-upload
                            action="asd"
                            :http-request="upload_imageClick"
                            :multiple="false"
                            name="image"
                            :limit="1"
                            :file-list="fileList">
                                <el-button size="small" type="primary">点击上传</el-button>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item label="备注信息">
                    <el-input type="textarea"  v-model="formInline.remark" placeholder="备注信息"></el-input>
                </el-form-item>

                <el-form-item label="套餐选择">
                    <el-select v-model="value1" class="tapcan-class"  @change="selectTagClick" multiple placeholder="请选择">
                        <el-option
                        v-for="item in comboList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-table
                    :data="tableList"
                    style="width: 100%">
                    <el-table-column
                        prop="value"
                        label="套餐类型">
                    </el-table-column>
                    <el-table-column
                        prop="input"
                        label="折扣">
                        <template slot-scope="scope">
                            <div>
                                <el-input 
                                    size="small" 
                                    v-model="scope.row.input" ></el-input>
                            </div>
                        </template>
                    </el-table-column>
                    
                </el-table>

            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button  :loading="ButtonLoading" type="primary" @click="confirmButtonClick">确 定</el-button>
            </div>
        </el-dialog>


        <el-dialog
        title="入库"
        :visible.sync="addpopupStatus"
        width="80%">
            <el-form :inline="true" :model="formInline" class="demo-form-inline" style=" display: flex; align-items: center;">
                <el-form-item label="扫码入库">
                    <el-input ref="inputCode" v-model="Qrcode" class="code-class" :autofocus="true" @change="GteCode"  placeholder="将鼠标聚焦于此，即可进行扫码入库"></el-input>
                </el-form-item>
                <el-form-item label="手动添加">
                    <el-input v-model="xinghao" placeholder="请输入商品型号" @change="Gtexinghao" ></el-input>
                </el-form-item>
				<el-form-item>
					<div style="padding-left: 20px;">
						<div style="white-space: nowrap;">出库数量：{{totalNum}}</div>
						<div style="white-space: nowrap;">金额统计：{{totalMony}}</div>
					</div>
				</el-form-item>
				
            </el-form>

            <el-table
                :data="saomaList"
                style="width: 100%">
                <el-table-column
                    prop="id"
                    label="ID">
                </el-table-column>
                <el-table-column
                    prop="bar_code"
                    label="条形码">
                </el-table-column>
                <el-table-column
                    prop="goods_brand"
                    label="商品品牌">
                </el-table-column>
                <el-table-column
                    prop="goods_class"
                    label="商品类别">
                </el-table-column>
                <el-table-column
                    prop="goods_color"
                    label="商品颜色">
                </el-table-column>
                <el-table-column
                    prop="goods_norms"
                    label="商品规格">
                </el-table-column>
                <el-table-column
                    prop="goods_type"
                    label="型号">
                </el-table-column>
                <el-table-column
                    label="二维码">
                    <template slot-scope="scope">
                        <div>
                            <el-input v-model="scope.row.qr_code" placeholder="将鼠标聚焦到此，进行扫码"/>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    label="数量">
                    <template slot-scope="scope">
                        <div>
                            <el-input v-model="scope.row.goods_num" placeholder="请输入数量"/>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    label="备注">
                    <template slot-scope="scope">
                        <div>
                            <el-input v-model="scope.row.remark" placeholder="请输入备注" />
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    label="操作">
                    <template slot-scope="scope">
                        <div>
                            <el-button @click="delDataClick(scope)" type="text">删除</el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <span slot="footer" >
                <el-button @click="addpopupStatus = false">取 消</el-button>
                <el-button type="primary" :loading="sahomaButtonStatus" @click="rukuClick">确 定</el-button>
            </span>
        </el-dialog>


        <el-dialog title="添加套账" :visible.sync="addEdit" @close="addEditcoloseClick" :destroy-on-close="true" width="40%">
            <el-form :model="formInline" class="demo-form-inline">
                <el-form-item label="套餐选择">
                    <el-select v-model="value1" class="tapcan-class"  @change="selectTagClick" multiple placeholder="请选择">
                        <el-option
                        v-for="item in comboList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>

            <el-table
                :data="tableList"
                style="width: 100%">
                <el-table-column
                    prop="value"
                    label="套餐类型">
                </el-table-column>
                <el-table-column
                    prop="input"
                    label="折扣">
                    <template slot-scope="scope">
                        <div>
                            <el-input 
                            size="small" 
                            v-model="scope.row.input" ></el-input>
                        </div>
                    </template>
                </el-table-column>
            </el-table>

            <div slot="footer" class="dialog-footer">
                <el-button @click="addEdit = false">取 消</el-button>
                <el-button  :loading="ButtonLoading" type="primary" @click="confirmAddClick">确 定</el-button>
            </div>
        </el-dialog>

        
    </div>
</template>

<script>
import { index_list , index_add,index_del,index_index,index_showgoodsdetail,scancode,indexcount,editgoods,editreward,editgoods_detail,editremark} from '@/api/procurement.js'
import { combo_list} from '@/api/combo.js'
import { upload_image} from '@/api/index.js'

// 解析二维码
import {getQrUrl} from '@/utils/qrCode.js'

export default {
    name:'Entrepot',
    data(){
        return{
            limit:{
                page:1,
                page_size:10,
                goods_type:''
            },
            tongji:{
                page:1,
                page_size:10,
                is_shipment:true,
            },
            pageArray:this.$store.state.pageArray,
            scanStatus:false,
            pageType:1,
            loading:false,
            tableData:[],
            total:0,
            totals:0,
            currentPage:1,
            dialogFormVisible:false,
            formInline: {
                bar_code:'',
                qr_code:'',
                goods_type:'',
                goods_brand:'',
                goods_norms:'',
                goods_class:'',
                goods_color:'',
                goods_num:1,
                goods_other:'',
                buy_set_meal_id:'',
                sell_set_meal_id:'',
                goods_url:'',
                price:'',
                goods_detail:'',
            },
            value1:'',
            options:[],
            comboList:[],
            ButtonLoading:false,
            fileList:[],
            tableList:[],
            addpopupStatus:false,
            saomaList:[],
            Qrcode:'',
            xinghao:'',
            sahomaButtonStatus:false,
            tableDatas:[],
            addEdit:false,
            seleArray:[],
            selectRow:{}
        }
    },
    computed: {
        Allprice() {
            return function(price,num){
                return parseFloat(price) * parseFloat(num)
            }
        },
        AllAward(){
            return function(reward,num){
                return parseFloat(reward) * parseFloat(num)
            }
        },
		totalNum(){
			var number=0
			for(var i=0;i<this.saomaList.length;i++){
				var num=this.saomaList[i].goods_num
				number+=parseFloat(num)
			}
			return number
		},
		totalMony(){
			// total
			var number=0
			for(var i=0;i<this.saomaList.length;i++){
				var num=(this.saomaList[i].price)*(this.saomaList[i].goods_num)
				number+=parseFloat(num)
			}
			return number
		}
    },
    created(){
        // 获取首页数据
        this.getlist()
        // 获取部门列表
        // this.get_department_list()
        // 获取套餐列表
        this.get_combo_list()

    },
    methods:{
        // 仓库管理列表
        getlist(){
            index_index(this.limit).then(res=>{
                let data = res.data.data
                if(JSON.stringify(data) != undefined){
                    data.forEach(element => {
                        element.editStatus = false
                        element.editButton = false
                    });
                    this.total = res.data.total
                }
                this.tableData = data
                this.loading = false
            })
        },

        // 搜索
        selecCLick(){
            this.loading = true
            if(this.pageType == 1){
                this.limit.limit = 1
                this.getlist()
            }else{
                this.tongji.limit = 1
                this.getStatisticsData()
            }
            
        },
        // 用户修改上传详情图
        uploadsClick(file){
            upload_image({image:file.file}).then(res=>{
                let data = {
                    goods_type:this.selectRow.goods_type,
                    goods_detail:res.data,
                }
                editgoods_detail(data).then(res=>{
                this.$message({
                    type:'success',
                    message:'修改成功'
                })
                this.getStatisticsData()
                })
            })
        },
        //保存当前选中的标识
        updataimage(row){
        this.selectRow = row
        },

        //根据二维码获取数据
        getQRData(code){
            index_showgoodsdetail({pm:code}).then(res=>{
                this.Qrcode = ''
                this.xinghao = ''
                if(res.code == 200){
                    this.$message({
                        type:'success',
                        message:res.msg
                    })
                    if(res.data.id){
                        let data = res.data
                        data.goods_num = 1
                        data.remark = ''
                        data.qr_code = ''
                        this.saomaList.unshift(data)
                    }
                }
                
            })
			console.log(this.saomaList)
        },

        //删除本地数据
        delDataClick(row){
            this.saomaList.splice(row.$index, 1);
        },

        // 确定新增按钮
        confirmButtonClick(){
            let buy_set = this.tableList[0]
            this.formInline.buy_set_meal_id = '{' + '"' + buy_set.id + '":"' + buy_set.input + '"}'
            let newArray = this.tableList.slice(1)
            if(newArray.length <= 0){
                this.formInline.sell_set_meal_id = ''
            }else{
                let newString = ''
                newArray.forEach(element =>{
                    if(newString){
                        // 存在
                        newString = newString + ',"' + element.id + '":"' + element.input +'"'
                    }else{
                        // 不存在
                        newString = '"' + element.id + '":"' + element.input +'"'
                    }
                })
                this.formInline.sell_set_meal_id = '{' + newString + '}'
            }
            console.log(this.formInline)
            this.ButtonLoading = true
            index_add(this.formInline).then(res=>{
                this.ButtonLoading = false
                this.dialogFormVisible = false
                if(res.code == 200){
                    this.getlist()
                    this.$message({
                        type: 'success',
                        message: res.msg
                    });
                }
            })  
        },

        // 点击删除
        delClick(row){
            this.$confirm('此操作将永久删除 , 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    index_del({id:row.id}).then(res=>{
                        if(res.code == 200){
                            // 确定删除
                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                            this.getlist();
                        }
                    })
                    
                }).catch(() => {         
            });
        },
        // 添加套账
        addEditData(){
            if(this.seleArray.length <= 0){
                this.$message({
                    type:'warning',
                    message:'请选择需要增加套账的数据'
                })
                return
            }
            this.addEdit = true
        },

        handleSelectionChange(val){
            if(val.length <= 0){
                return
            }
            let arr = []
            val.map(res=>{
                arr.push(res.goods_type)
            })
            this.seleArray = arr
        },
        
        // 确认添加套账
        confirmAddClick(){
            let newString = ''
            this.tableList.forEach(element =>{
                if(newString){
                    // 存在
                    newString = newString + ',"' + element.id + '":"' + element.input +'"'
                }else{
                    // 不存在
                    newString = '"' + element.id + '":"' + element.input +'"'
                }
            })
            newString = '{' + newString + '}'
            let data = {
                goods_type:this.seleArray,
                set_meal_id:newString
            }
            this.ButtonLoading = true
            editgoods(data).then(res=>{
                this.ButtonLoading = false
                this.addEdit = false
                this.$message({
                    type:'success',
                    message:res.msg
                })
            })
        },

        addEditcoloseClick(){
            this.tableList = []
            this.value1 = ''
        },

        // 启动扫码入库
        startButtonClick(){
            this.scanStatus = true
            this.$message({
                type:'success',
                message:'开启成功，请不要点鼠标，开始扫码'
            })
            this.$nextTick(function () {
                this.$refs.inputCode.focus()
            });
        },

        // 入库
        rukuClick(){
            if(this.saomaList.length <= 0){
                this.$message({
                    type:'warning',
                    message:'请扫码或者手动添加'
                })
                return
            }
            this.sahomaButtonStatus= true
            scancode(this.saomaList).then(res=>{
                console.log(res)
                if(res.code == 200){
                    this.addpopupStatus = false
                    this.saomaList = []
                    this.$message({
                        type:'success',
                        message:res.msg
                    })
                    this.getlist()
                }
                this.sahomaButtonStatus= false
            })
        },

        // 请求统计查询数据
        getStatisticsData(){
            let data = {
                page:this.tongji.page,
                page_size:this.tongji.page_size,
                goods_type:this.limit.goods_type
            }
            if(this.tongji.is_shipment){
                data.is_shipment = 1
            }else{
                data.is_shipment = 0
            }
            indexcount(data).then(res=>{
                let data = res.data.data
                if(JSON.stringify(data) != undefined){
                    data.forEach(element => {
                        element.editStatus = false
                        element.editButton = false
                    });
                    this.totals = res.data.total
                }
                this.tableDatas = data
                this.loading = false
            })
        },
  

        //扫二维码上传商品
        GteCode(){
            this.getQRData(this.Qrcode)
        },
        Gtexinghao(){
            this.getQRData(this.xinghao)
        },
        


        //仓库  库存切换
        warehouseClick(key){
            if(this.pageType == key){
                return
            }
            this.loading  = true
            if(key == 1){
                this.getlist()
            }else{
                this.getStatisticsData()
            }
            this.pageType = key
        },

        // 选择部门
        selectBottomClick(column,key,row){
            let data = {
                warehouse_id:column.id,
                department_id:key.id
            }
            this.tableData[row].department = key.label
            update(data).then(res=>{})
        },
        // 
        selectcLick(row){
            console.log('************')
            console.log(row)
        },

        // 多选输入框
        selectTagClick(row){
            this.tableList = []
            this.comboList.forEach((element,index) =>{
                this.comboList.forEach( item =>{
                    if(item.value == row[index]){
                        this.tableList.push({
                            id:item.value,
                            value:item.label,
                            input:''
                        })
                    }
                })
            })
        },

        // 选择套餐
        selectComboClick(column,key,row){
            let data = {
                warehouse_id:column.id,
                set_meal:key.id
            }
            this.tableData[row].set_meal_info.name = key.label
            this.tableData[row].set_meal_info.id = key.id
            update(data).then(res=>{})
        },

        // 单元格双击
        cellOneClick(row, column, cell, event){
            this.tableData[row].editStatus = true
            this.tableData[row].editButton = true
        },
        cellOneClicks(row, column, cell, event){
            this.tableDatas[row].editStatus = true
            this.tableDatas[row].editButton = true

        },

        // 编辑奖励失去焦点
        updataData(row, column, cell, event){
            console.log(column)
            this.tableDatas[row].editStatus = false
            this.tableDatas[row].editButton = false
            let data = {
                goods_type:column.goods_type,
                reward:column.reward,
            }
            editreward(data).then(res=>{
                if(res.code == 200){
                    this.$message({
                        type:'success',
                        message:res.msg
                    })
                }
                
            })
        },

        // 保存按钮
        editClick(row, column, cell, event){
            let data = {
                id:column.id,
                remark: column.remark,
                qr_code: column.qr_code
            }
            this.tableData[row].editStatus = false
            this.tableData[row].editButton = false
            editremark(data).then(res=>{})
        },

        // 获取部门列表
        get_department_list(){
            department_list().then(res=>{
                this.options = res.data.map(item => ({
                    id:item.id,
                    label: item.name
                }));
            })
        },

        // 上传商品图片
        uploadClick(file){
            upload_image({image:file.file}).then(res=>{
                this.formInline.goods_url = res.data
            })
        },

        // 上传商品说明图
        upload_imageClick(file){
            upload_image({image:file.file}).then(res=>{
                this.formInline.goods_detail = res.data
            })
        },
        // 二维码并解析
        qrCodeClick(file){
            const result = getQrUrl(file.file)
            result.then(res => {
                if (res.data) {
                    console.log(res.data)
                    if(this.formInline.qr_code){
                        this.formInline.qr_code = ',' + res.data
                    }else{
                        this.formInline.qr_code = res.data
                    }
                    this.$message.success('识别二维码成功!')
                } else {
                    this.$message.error('识别二维码失败, 请重新上传')
                }
            }).catch(err => {
                this.$message.error(JSON.stringify(err))
            })
        },

        // 获取套餐列表
        get_combo_list(){
            combo_list().then(res=>{
                this.comboList = res.data.map(item => ({
                    value:item.id,
                    label: item.name
                }));
            })
        },

        //关闭时候的回调
        coloseClick(){
            this.tableList = []
            this.value1 = ''
            this.formInline.qr_code = ''
        },

        // 级联选中触发
        handleChange(item){
            console.log(item)
            this.formInline.province_id = item[0]
            this.formInline.city_id = item[1]
            this.formInline.district_id = item[2]
        },


        /**
         * 点击页码触发
         */
        handleSizeChange(val) {
            this.limit.page_size = val
            this.loading = true
            this.getlist()
        },
        handleCurrentChange(val) {
            this.limit.page = val
            this.loading = true
            this.getlist()
        },

         /**
         * 点击页码触发
         */
        handleSizeChanges(val) {
            this.tongji.page_size = val
            this.loading = true
            this.getStatisticsData()
        },
        handleCurrentChanges(val) {
            this.tongji.page = val
            this.loading = true
            this.getStatisticsData()
        }

    }
}
</script>

<style scoped>
.beizhu{
    color: #FFAF24;

}
.entrepot-title-all{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.flex-all{
    display: flex;
    align-items: center;
}
.entrepot-title{
    width: 220px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    line-height: 90px;

}
.entrepot-color{
    color: #428CFF;
}
.addButton-class{
    background: linear-gradient(90deg, #FF774E, #FF4C16);
    box-shadow: 0px 5px 30px 0px rgba(255, 78, 24, 0.4);
    border-radius: 6px;
    border: none;
    color: #fff;
}
.input-all{
    width: 90%;
}
.search-class{
    background: #2290FF;
    color: #fff;
    border:none

}
.start-class{
    background: linear-gradient(90deg, #FF861B, #FFB527);
    box-shadow: 0px 5px 30px 0px rgba(255, 142, 66, 0.4);
    border-radius: 6px;
    border: none;
    color: #fff;

}
.add-class{
    background: linear-gradient(90deg, #2AD38C, #14C8AD);
    box-shadow: 0px 5px 30px 0px rgba(42, 211, 140, 0.4);
    border-radius: 6px;
    border: none;
    color: #fff;
}
.code-class{
    width: 400px;
    margin-right: 40px;
}
.tapcan-class{
    width: 80%;
}
.blurClick{
    width: 500px;
}
.add-classs{
    background: linear-gradient(90deg, #FF774E, #FF4C16);
    box-shadow: 0px 5px 30px 0px rgba(255, 77, 23, 0.4);
    border-radius: 6px;
    border: none;
    color: #fff;
    margin-right: 20px;
}
.flex-allll{
    margin-right: 40px;
}
</style>